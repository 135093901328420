import React from "react";
import { Link } from "gatsby"
import { withTranslation } from "react-i18next"
import Img from 'gatsby-image'
import { tinyParallaxInit } from '../../dist/js/tiny-parallax-init'
import './breadcrumb.scss'

class Breadcrumb extends React.Component {

    componentDidMount() {
        const $ = require('jquery')
        tinyParallaxInit(this.bgOffset, this.bgMovePercent)
        $('.breadcrumb-banner-area').tinyparallax();
    }
    
    render() {
        const { slug, image, bgImage, bgPadding, title, title2, textShadow, t } = this.props
        let { bgImageOverlay } = this.props
        this.bgOffset = parseInt(this.props.bgOffset) || 0
        this.bgMovePercent = parseInt(this.props.bgMovePercent) || 0.1

        let breadcrumb = slug.split("/")
        let path = ""
    
        let breadcrumbObj = breadcrumb.map((crumb, index) => {
            if (index < 1) return
            
            path += "/" + crumb
            if (index < breadcrumb.length - 1) {
                return {path: path, name: t(crumb)}
            } else {
                return {path: path, name: title}
            }  
        })
    
        breadcrumbObj.shift()
        let styleObj = {backgroundImage: "url(" + bgImage.fluid.src + ")"}
        if (bgPadding !== undefined) {
            styleObj.paddingTop = bgPadding
            styleObj.paddingBottom = bgPadding
        }

        if (bgImageOverlay !== true) {
            bgImageOverlay = false
        }

        return (
                <div className={"breadcrumb-banner-area" + (bgImageOverlay ? " overlay" : "")} style={styleObj}>
                    <div className="sticky-spacer" />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className={(textShadow !== undefined && textShadow ? "textShadow " : "") + "breadcrumb-text"}>
                                    {title !== undefined && (<h1 className="text-center">{title}<br/>{title2}</h1>)}
                                    {image !== undefined && (<div className="text-center">{title}<br/>{title2}</div>)}
{/*                                     <div className="breadcrumb-bar">
                                        <ul className="breadcrumb text-center">
                                            {breadcrumbObj.map((item, index) => (
                                                <li><Link to={item.path}>{item.name}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
*/}                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

}

export default withTranslation()(Breadcrumb)
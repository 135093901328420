import React from "react";
import { withTranslation } from "react-i18next";
import SocialShare from "../SocialShare"
import Heading from "../Heading"
import './gdpr.scss'
class Gdpr extends React.Component {

    render() {

        const { data, content, t, i18n } = this.props
        return(
            <div id="gdpr" class="blog-area blog-no-sidebar pt-100 pb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 blog-post-item ml-auto mr-auto">
                            <div class="blog-wrapper blog-details">
                                <div class="blog-content">
                                <Heading title={data.pageTitle} subtitle={data.pageSubtitle} className="mb-50" />
                                {i18n.language === 'hu' && (
                                    <div className="gdpr_download_block row">
                                        <h4>Adatkezelési dokumentumaink itt letölthetőek</h4>
                                        <a className="link-btn col-md-3 col-xs-12" href="/files/hotel-tiliana-privacy-notice-hu.pdf" target="_blank">
                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            <p>
                                                2.0 Adatkezelési szabályzat
                                            </p>
                                        </a>
                                        <a className="link-btn col-md-3 col-xs-12" href="/files/2_1_1_Szallovendeg_adatkezelesi_tajekoztato_HH TilianaHotel.pdf" target="_blank">
                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            <p>
                                                2.1.1 Szallóvendég adatkezelési tájékoztató
                                            </p>
                                        </a>
                                        <a className="link-btn col-md-3 col-xs-12" href="/files/2_1_2_melleklet_Adatkerolap_szemelyes_adatok_kezelesehez-HH Tiliana Hotel.pdf" target="_blank">
                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            <p>
                                                2.1.2 Adatlap személyes adatok kezeléséhez
                                            </p>
                                        </a>
                                        <a className="link-btn col-md-3 col-xs-12" href="/files/2_4_melleklet_Vendegtajekoztato_elektronikus_megfigyelesrol-HH Tiliana Hotel.pdf" target="_blank">
                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            <p>
                                                2.4 Vendégtájékoztató elektronikus megfigyelésről
                                            </p>
                                        </a>
                                    </div>
                                )}
                                <div dangerouslySetInnerHTML={{__html: content}} />
                                {i18n.language === 'en' && (
                                    <div className="gdpr_download_block row">
                                        <a className="link-btn col-md-3 col-xs-12" href="/files/hotel-tiliana-privacy-notice-en.pdf" target="_blank">
                                            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            <p>
                                                Privacy Notice
                                            </p>
                                        </a>
                                    </div>
                                )}
                                </div>
                            </div>
                            <div class="common-tag-and-next-prev mt-60">
                                <SocialShare title={data.title} hashtags="tiliana-gdpr" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Gdpr)
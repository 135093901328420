import React from "react"
import { withTranslation } from "react-i18next"
import { Location } from "@reach/router"
import './socialshare.scss'

class SocialShare extends React.Component {

    trimHashtags = (hashtags) => {
        return hashtags.replace(/\s/g, '')
    }
    
    render() {
        const { className, title, blogShare, t } = this.props
        const defaultHashtags = this.trimHashtags(t('default_keywords'))
        const hashtags = (this.props.hashtags === undefined) ? defaultHashtags : defaultHashtags + "," + this.trimHashtags(this.props.hashtags)
        return (
            <Location>
                {({ location }) => {
                    return (
                        <div className={(blogShare !== undefined && !blogShare ? "" : "blog-share ") + (className !== undefined ? className : "")}>
                            {className !== undefined && className.indexOf('social-icons') > -1 && (
                            <ul>
                                <li><a href={"https://www.facebook.com/sharer/sharer.php?u=" + location.href}><i class="fa fa-facebook"></i></a></li>
                                <li><a href={"http://twitter.com/share?text=" + title + "&url=" + location.href + "&hashtags=" + hashtags}><i class="fa fa-twitter"></i></a></li>
                            </ul>)}
                            {(className === undefined || className.indexOf('social-icons') < 0) && 
                            (<><h6>{t('share')}:</h6>
                                <ul>
                                    <li><a href={"https://www.facebook.com/sharer/sharer.php?u=" + location.href}>Facebook</a></li>,
                                    <li><a href={"http://twitter.com/share?text=" + title + "&url=" + location.href + "&hashtags=" + hashtags}>Twitter</a></li>
                                </ul>
                            </>)}
                        </div>
                    )
                }}
            </Location>
        )
    }
}

export default withTranslation()(SocialShare)

import React from 'react'
import PropTypes from 'prop-types'
import "./heading.scss"

const Heading = ({title, title2, subtitle, className}) => (
    <div className={"section-title title" + (className !== undefined ? " " + className : "")}>
        <span>The hotel <strong>unforgettable</strong></span>
        <h3>{title}<br/>{title2}</h3>
        {subtitle && (
            <h4 className="subtitle">{subtitle}</h4>
        )}
    </div>
)

Heading.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    className: PropTypes.string,
}  

export default Heading